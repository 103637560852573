<template>
    <div class="cardV2">
        <div class="cardV2_head">
            <div>
                <div>
                    <div class="rmb">¥</div>
                    {{ marketPrice }}
                </div>
                <div class="tag" v-if="tag">{{tag}}</div>
            </div>
        </div>
        <div class="cardV2_discount" style="margin-right:5px" v-if="deduct > 0">
            人生券最高抵扣¥{{ deduct }}元
        </div>
        <div class="cardV2_description">
            {{ title }}
        </div>
        <div class="location" v-if="locationText && !hideLocation">
            <img src="https://api.mengjingloulan.com/storage/wechat/services/location-2.png"/>
            {{locationText}}
        </div>
        <div class="cardV2_tip" v-if="returnExchange == 1">
            提示：{{returnExchangeText}}
        </div>
    </div>
</template>

<script>
  export default {
    name: 'cardV2',
    props: {
      mail_type: null,
      title: {
        type: String,
        default: '',
      },
      marketPrice: {
        type: [String, Number],
        default: '',
      },
      deduct: {
        type: [String, Number],
        default: 0,
      },
      returnExchange: {
        type: Number,
        default: 0, //0 可以退款 1不可以退款
      },
      returnExchangeText: {
        type: String,
        default: '该商品属于定制商品，下单后不可退换'
      },
      tag: {
        type: String,
        default: ''
      },
      locationText: {
        type: String,
        default: '福成上上城理想新城'
      },
      hideLocation: {
        type: Boolean,
        default: false
      }
    },
    mounted () {},

    methods: {},
  }
</script>

<style lang="scss" scoped>
    .cardV2 {
        position: relative;
        box-sizing: border-box;
        padding: 15px 15px;
        width: 345px;
        background: #ffffff;
        border-radius: 6px;

        .cardV2_head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a2a2a2;
            & > div:first-of-type {
                display: flex;
                align-items: center;
                & > div:first-of-type {
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #c10000;
                    display: flex;
                    align-items: center;

                    .rmb {
                        font-size: 16px;
                        margin-right: 2px;
                    }
                }
            }

            .sale_number {
                margin-right: 10px;
            }

            .tag {
                position: absolute;
                right: 10px;
                top: 16px;
                background: linear-gradient(139deg, #FFB966 0%, #F46A17 100%);
                border-radius: 16px;
                height: 16px;
                padding: 0 5px;
                font-size: 10px;
                color: #fff;
                font-family: PingFangSC-Regular, PingFang SC;
                align-items: center;
                max-width: 80px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                word-break: break-all;
                margin-right: 3px;
                line-height: 16px;
                text-decoration: none;
            }
        }
        .cardV2_discount {
            box-sizing: border-box;
            display: inline-block;
            margin-top: 10px;
            border: 1px solid #f46a17;
            padding: 4px 7px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #eb6100;
        }
        .cardV2_description {
            margin-top: 12px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 20px;
            color: #333333;
        }

        .location {
            margin-top: 10px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            color: #939393;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;

            img {
                width: 12px;
                height: 12px;
                margin-right: 2px;
            }

        }

        .cardV2_tip {
            margin-top: 16px;
            height: 29px;
            background: #fff9ec;
            border-radius: 2px;
            display: inline-flex;
            align-items: center;
            padding: 0 10px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #df8621;
        }
    }
</style>
