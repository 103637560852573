<template>
  <div class="swiperV2" :style="{height:imgHeight+'px'}">
    <swiper class="swiper" :options="swiperOption" ref="mySwiper">
      <!-- <img
      class="swiperV2_play"
      v-if="isShowPlay"
      :src="require('@STATIC/image/icons/play.png')"
    /> -->

      <swiper-slide v-for="(item, index) in list" :key="index">
        <div class="swiperV2_item" :style="{height:imgHeight+'px'}">
          <img
            v-if="item.type == 'image'"
            :src="item.url"
            :style="{height:imgHeight+'px',width:imgHeight+'px'}"
          />
          <vue-core-video-player
            v-else
            :src="item.url"
            :loop="false"
            ref="video"
            :cover="videoCover"
          >
          </vue-core-video-player>
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiperV2_foot" v-if="hasVideo && hasImage">
      <button
        v-if="hasVideo"
        :class="{ 'swiperV2_foot-active': !isImg }"
        @click.stop="onBtn('video')"
      >
        视频
      </button>
      <button
        v-if="hasImage"
        :class="{ 'swiperV2_foot-active': isImg }"
        @click.stop="onBtn('image')"
      >
        图片
      </button>
    </div>
    <div class="swiperV2_tip" v-if="list.length > 0">
      {{ activeIndex + 1 }}/{{ list.length }}
    </div>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "swiperV2",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      swiperOption: {
        loop: false,
        autoplay: false,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        observer: true, // 启动动态检查器(OB/观众/观看者)，当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper。
        observeParents: true, // 将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新。
        setWrapperSize: true, // Swiper使用flexbox布局(display: flex)，
      },
      options: {
        // autoplay: true,
        playsinline: true,
        muted: true,
        controls: false,
      },
      swiper: null,
      activeIndex: 0,
      videoCover: "",
      imgHeight:window.innerWidth
    };
  },
  mounted() {
    let video = this.list.find((val) => val.type == "video");
    if (video) {
      this.getVideoBase64(
        "https://fld-wenzhi-1305125648.cos.ap-guangzhou.myqcloud.com/file/2.mp4"
      ).then((res) => {
        this.videoCover = res;
      });
    }

    this.$nextTick(() => {
      this.swiper = this.$refs.mySwiper.swiper;
      this.swiper.init();
    });
  },
  watch: {
    "swiper.activeIndex"(newC, oldC) {
      this.activeIndex = newC;
    },
  },
  computed: {
    isImg() {
      //   console.log(this.activeIndex)
      if (this.list.length > 0) {
        return this.list[this.activeIndex].type == "image";
      } else {
        return true;
      }
    },
    hasVideo() {
      return this.list.filter((val) => val.type == "video").length > 0;
    },
    hasImage() {
      return this.list.filter((val) => val.type == "image").length > 0;
    },
    isShowPlay() {
      console.log(this.$refs["video_" + this.activeIndex]?.state?.playing);
      return (
        !this.isImg && !this.$refs["video_" + this.activeIndex]?.state?.playing
      );
    },
  },
  methods: {
    onItemClick(item, index) {
      console.log("预览");
    },
    onBtn(type) {
      if (type == "image") {
        if (this.$refs.video) {
          this.$refs.video[0].pause();
        }
      }
      let index = this.swiper.activeIndex;
      this.list.map((val, idx) => {
        if (val.type == type) {
          index = idx;
        }
      });
      this.swiper.slideTo(index, 1000, false);
    },
    /*
     * 截取视频的第一帧
     */
    getVideoBase64(url) {
      return new Promise(function (resolve) {
        let dataURL = "";
        let video = document.createElement("video");
        video.setAttribute("crossOrigin", "anonymous"); //处理跨域
        video.setAttribute("src", url);
        video.setAttribute("width", 400);
        video.setAttribute("height", 240);
        video.setAttribute("autoplay", "autoplay");
        video.addEventListener("canplaythrough", function () {
          let canvas = document.createElement("canvas"),
            width = video.width, //canvas的尺寸和图片一样
            height = video.height;
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
          dataURL = canvas.toDataURL("image/jpeg"); //转换为base64
          resolve(dataURL);
        });
      });
    },
  },
  updated() {
    this.$nextTick(() => {
      this.swiper.init();
    });
  },
};
</script>

<style lang="scss" scoped>
@include b(swiperV2) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  //   height: 100%;
  @include e(play) {
    position: absolute;
    z-index: 2;
    width: 50px;
    height: 50px;
  }
  @include e(foot) {
    position: absolute;
    z-index: 2;
    bottom: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    border-radius: 11px;
    background: #fbfaf9;
    & > button {
      height: 100%;
      width: 50px;
      border-radius: 11px;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    @include m(active) {
      background: #f46a17 !important;
      color: #ffffff !important;
    }
  }
  @include e(tip) {
    position: absolute;
    z-index: 2;
    bottom: 22px;
    right: 15px;
    width: 42px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 9px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  @include e(item) {
    width: 100%;
    height: 100%;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  @include b(swiper) {
    width: 100%;
    height: 100%;
  }
}
</style>
