<template>
    <rsck-page
            :show-page-bottom-button="true"
            :bottom-height="50"

    >
        <rsck-refresh
                ref="refresh"
                padding="0"
                :height="scrollHeight"
                @onPullDown="onPullDown"
                @onPullUp="onPullUp"
                v-if="showResult"
        >
            <div>
                <swiper :list="swiperList"></swiper>
            </div>
            <div class="goods-info">
                <card-v2
                        :title="goodsInfo.title"
                        :deduct="goodsInfo.deduct"
                        :sales-number="goodsInfo.sale_number"
                        :product-price="goodsInfo.product_price"
                        :market-price="goodsInfo.price"
                        :mail-type="goodsInfo.mail_type"
                        :return-exchange="goodsInfo.return_exchange"
                        :tag="goodsInfo.tag"
                        :location-text="goodsInfo.village_name"
                        :hide-location="listTypeId == 2"
                />

                <cell-v3 class="share" :hide-icon="true" @click="shareGoods">
                    <div slot="title" class="title" @click="shareGoods"> 分享好友，秒赚佣金</div>
                    <div class="img" @click="shareGoods">
                        <img :src="require('@STATIC/image/icons/arrowRight.png')"/>
                    </div>
                </cell-v3>

                <div class="goods-description">
                    <div class="title">商品详情</div>
                    <div class="description" v-html="goodsInfo.contents"></div>
                </div>
            </div>

        </rsck-refresh>
        <div slot="pageBottomButtons" class="page-bottom-inner-container">
            <div class="contact" @click="contact">
                联系客服
            </div>
            <div class="goToBuy" @click="goToBuy">立即购买</div>
        </div>
    </rsck-page>
</template>

<script>
  import RsckPage from '@/components/common/rsck-page'
  import RsckRefresh from '@/components/common/rsck-refresh'
  import swiper from '@T/community/swiper/swiperV1'
  import CardV2 from '@/template/community/card/cardV2'
  import CellV3 from '@/template/community/cell/cellV3'

  export default {
    name: 'goods',
    components: {CellV3, CardV2, swiper, RsckRefresh, RsckPage},
    data () {
      return {
        goodsInfo: {},
        showResult: false,
        scrollHeight: 0,
        listTypeId: 1,
        id: 0,
        contactMobile: '',
        canContinue: true
      }
    },
    mounted () {
      document.title = '商品详情'
      this.scrollHeight = window.innerHeight - document.querySelector('.page-bottom-inner-container').clientHeight
      let query = this.$route.query
      this.listTypeId = query?.listTypeId || 1
      this.id = query?.id || 0
      this.getGoodsDetail()
      this.getContactMobile()
    },
    computed: {
      swiperList () {
        let list = [],
          imgList = []

        imgList = this.goodsInfo?.images.map((val) => ({
          type: 'image',
          url: val,
        }))
        list = imgList
        return list
      },
    },
    methods: {
      onPullDown () {
        this.$refs.refresh.endPullDown()
      },
      onPullUp () {
        this.$refs.refresh.endPullUp()
      },
      getGoodsDetail () {
        return new Promise((resolve, reject) => {
          this.$api.services.getGoodsDetail({
            list_type_id: this.listTypeId,
            id: this.id
          }).then(response => {
            this.goodsInfo = response.data
            this.showResult = true
            resolve()
          }).catch(error => {
            this.$toast.fail(error.message)
            reject()
          })
        })
      },
      share () {

      },
      getContactMobile () {
        this.$api.services.getContactMobile().then(response => {
          this.contactMobile = response.data
        })
      },
      //联系负责人
      contact () {
        let self = this
        this.$dialog({
          title: '温馨提示',
          content: `确定要给${this.contactMobile}打电话吗？`,
          closeOnClickModal: false, //点击蒙层是否关闭对话框
          closeBtn: false, //显式右上角关闭按钮
          onOkBtn () {
            self.$dialog.closed()
            if (self.$platform.wxsdk.isWechat()) {
              self.$platform.wxsdk.wxRoute({
                type: 'navigateTo',
                url:
                '/web/makePhoneCall/common?mobile=' +
                this.contactMobile,
              })
            } else {
              window.location.href = 'tel:' + this.contactMobile
            }
          },
        })
      },
      goToBuy () {
        if (this.canContinue) {
          this.canContinue = false

          let query = {
            listTypeId: this.listTypeId,
            id: this.id
          }
          query = encodeURIComponent(JSON.stringify(query))
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/services/order-confirm?query=' + query,
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      },
      shareGoods () {
        if (this.canContinue) {
          this.canContinue = false
          let params = {
            id: this.goodsInfo.id,
            title: this.goodsInfo.title,
            cover_path: this.goodsInfo.cover_path,
            listTypeId: this.listTypeId
          }
          let query = encodeURIComponent(JSON.stringify(params))
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/share/share-second?query=' + query,
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      }
    }
  }
</script>

<style scoped lang="scss">
    .goods-info {
        padding: 10px 15px;
        display: flex;
        flex-wrap: wrap;

        .share {
            width: 100%;
            background: #fff;
            margin: 10px 0;
            padding: 0 15px;
            height: 40px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            display: flex;

            .title {
                flex: 1;
                font-size: 14px;
                color: #EB6100;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
            }

            .img {
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                img {
                    width: 11px;
                    height: 11px;
                }
            }
        }

        .goods-description {
            width: 100%;
            padding: 10px 15px;
            display: flex;
            background: #fff;
            display: flex;
            flex-wrap: wrap;

            .title {
                width: 100%;
                color: #333333;
                font-size: 14px;
                font-weight: bold;
                font-family: PingFangSC-Semibold, PingFang SC;
            }

            .description {
                width: 100%;
                margin-top: 10px;
                color: #333333;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                line-height: 20px;

                /deep/ p {
                    margin: 0 !important;
                    padding: 0 !important;
                }
            }
        }
    }

    .page-bottom-inner-container {
        width: 100%;
        background: #fff;
        display: flex;
        justify-content: space-around;

        .contact {
            width: 166px;
            background: #FFECE1;
            height: 36px;
            -webkit-border-radius: 36px;
            -moz-border-radius: 36px;
            border-radius: 36px;
            display: flex;
            color: #F46A17;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            justify-content: center;
            align-items: center;

            a {
                color: #F46A17;
            }
        }

        .goToBuy {
            width: 166px;
            background: linear-gradient(135deg, #FFB56D 0%, #FF8116 100%);
            height: 36px;
            -webkit-border-radius: 36px;
            -moz-border-radius: 36px;
            border-radius: 36px;
            display: flex;
            color: #FFFFFF;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            justify-content: center;
            align-items: center;
        }
    }
</style>